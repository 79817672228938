import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class ModalComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.init();
    }

    static defaults = {
        /**
         * A selector that will be used to attach the click event needed to open the modal.
         *
         * @property modalComponent.options.triggerSelector
         * @type {Object}
         * @default null
         */
        triggerSelector: null,
    }

    KEY = {
        ESC: 27,
    }

    SELECTOR = {
        CLOSE: '[data-modal-role="close"]',
        FOCUS_CONTROL: '[data-focus-control]',
    }

    STATE = {
        ACTIVE: 's-isActive',
        LOCKED: 'u-isScrollLocked',
    }

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {ModalComponent}
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onTriggerClickHandler = this.onTriggerClick.bind(this);
        this.onCloseClickHandler = this.onCloseClick.bind(this);
        this.onKeydownHandler = this.onKeydown.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {ModalComponent}
     * @private
     */
    createChildren() {
        super.createChildren();

        this._$html = $(document.documentElement);
        this._$body = $(document.body);
        this.$close = this.$element.find(this.SELECTOR.CLOSE);
        this.$defaultFocusControl = this.$element.find(this.SELECTOR.FOCUS_CONTROL);

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {ModalComponent}
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {ModalComponent}
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this._$body.on('click', this.options.triggerSelector, this.onTriggerClickHandler);
        this._$body.on('keydown', this.onKeydownHandler);
        this.$close.on('click', this.onCloseClickHandler);

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {ModalComponent}
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        this._$body.off('click', this.options.triggerSelector, this.onTriggerClickHandler);
        this._$body.off('keydown', this.onKeydownHandler);
        this.$close.off('click', this.onCloseClickHandler);

        return this;
    }

    /*
        EVENT HANDLERS
    */
    onTriggerClick(e) {
        e.preventDefault();
        this.showModal();
    }

    onCloseClick(e) {
        this.closeModal();
    }

    onKeydown(e) {
        if (e.keyCode === this.KEY.ESC) {
            this.closeModal();
        }
    }

    /**
     * Displays the modal and accessibility attributes and
     * forces focus on default focusable item.
     *
     * @method showModal
     * @public
     */
    showModal() {
        this.$element.show();
        // This forces a repaint which makes the browser update transition properties
        this.$element.get(0).offsetWidth;
        this.$element.addClass(this.STATE.ACTIVE);
        this.$element.attr('aria-hidden', false);
        this.$defaultFocusControl.focus();
        this._$html.addClass(this.STATE.LOCKED);
    }

    /**
     * Closes the modal and resets accessibility attributes and
     * moves focus on back to modal trigger.
     *
     * @method closeModal
     * @public
     */
    closeModal() {
        const transitionDuration = parseFloat(this.$element.css('transitionDuration')) * 1000;

        setTimeout(() => {
            this.$element.hide();
        }, transitionDuration);

        this.$element.removeClass(this.STATE.ACTIVE);
        this._$html.removeClass(this.STATE.LOCKED);
        $(this.options.triggerSelector).focus();
        this.$element.attr('aria-hidden', true);
    }

}
