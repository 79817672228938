import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class SelectUpdateComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.init();
    }

    SELECTOR = {
        SELECT: '[data-select]',
        TARGET: '[data-target]',
    };

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {object} SelectUpdateComponent
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onChangeOptionHandler = this.onChangeOption.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {object} SelectUpdateComponent
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$select = this.$element.find(this.SELECTOR.SELECT);
        this.$target = this.$element.find(this.SELECTOR.TARGET);

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {object} SelectUpdateComponent
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {object} SelectUpdateComponent
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this.$select.on('change', this.onChangeOptionHandler);

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {object} SelectUpdateComponent
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        this.$select.off('change', this.onChangeOptionHandler);

        return this;
    }

    /**
     * Gets the selected <option> value
     *
     * @method getSelectedOptionValue
     * @returns {object} SelectUpdateComponent
     * @public
     */
    getSelectedOptionValue() {
        return this.$select.find(':selected').val();
    }

    /**
     * Updates the target value
     *
     * @method updateTargetValue
     * @param {string} text Can be a mix of text and HTML entities
     * @returns {object} SelectUpdateComponent
     * @public
     */
    updateTargetValue(text) {
        return this.$target.html(text);
    }

    /*
        EVENT HANDLERS
    */

    /**
     * Change event for select options
     *
     * @method onChangeOption
     * @param {event} e
     * @public
     */
    onChangeOption(e) {
        const text = this.getSelectedOptionValue();

        this.updateTargetValue(text);
    }
}
