import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class FormValidationComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.init();
    }

    static defaults = {
        confirmSubmission: false,
        confirmationMessage: null,
        handleSubmission: true,
        submitOnChange: false,
        isJsonOnly: false,
        disableOnSubmit: false,
        // Reduced: regex for  alpha-numberic, period, comma, apostrophes, space
        // eslint-disable-next-line max-len
        //Default: new RegExp('^[a-zA-Z]+$'), //alpha-numberic, period, comma, apostrphe, space, dollar sign, exclamation, hyphen, underscore
    }

    REGEX_PATTERNS = {
        DEFAULT: "[A-Za-z0-9-\s.\s,\s' ]+",
    }

    SELECTOR = {
        LABEL: '[data-validation-label]',
        INPUT: '[data-validation-input]',
        ERROR_MESSAGE: '[data-validation-error]',
        STRICT: '[data-strict-validation]',
        REMOVE: '[data-validation-role="remove"]',
        SUBMIT: '[data-validation-submit]',
        DEFAULT_VALIDATION: '[data-default-validation]',
    }

    STATE = {
        ACTIVE: 's-isActive',
        ERROR: 's-hasError',
        HIDDEN: 's-isHidden',
        VISUALLY_HIDDEN: 'u-isVisuallyHidden',
    }

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {FormValidationComponent}
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onSubmitHandler = this.onSubmit.bind(this);
        this.onRemoveBtnClickHandler = this.onRemoveBtnClick.bind(this);
        this.onChangeHandler = this.onChange.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {FormValidationComponent}
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$form = this.$element;
        this.$inputs = $(this.SELECTOR.INPUT);
        this.$labels = $(this.SELECTOR.LABEL);
        this.$errorMessages = $(this.SELECTOR.ERROR_MESSAGE);
        this.$removeBtn = this.$element.find(this.SELECTOR.REMOVE);
        this.$submit = this.$element.find(this.SELECTOR.SUBMIT);
        this.$defaultValidation = $(this.SELECTOR.DEFAULT_VALIDATION);

        return this;
    }

    /**
     * Performs measurements and applys any positioning style logic.
     * Should be run anytime the parent layout changes.
     *
     * @method layout
     * @returns {Object} FileUploadComponent
     * @public
     */
    layout() {
        super.layout();

        this.addValidationPatterns();

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {FormValidationComponent}
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {FormValidationComponent}
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        if (this.options.handleSubmission) {
            this.$form.on('submit', this.onSubmitHandler);
        }

        if (this.options.submitOnChange) {
            this.$form.on('change', this.onChangeHandler);
        }

        this.$removeBtn.on('click', this.onRemoveBtnClickHandler);

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {FormValidationComponent}
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;


        return this;
    }

    /*
        EVENT HANDLERS
    */
    onSubmit(e) {
        if (this.options.disableOnSubmit) {
            this.$submit.attr('disabled','');
        }

        if (!this.options.submitOnChange) {
            this.clearErrorStates();
            const shouldSubmit = this.checkInputValidity();

            if (!shouldSubmit) {
                e.preventDefault();
            }
        }
    }

    onRemoveBtnClick(e) {
        // Prompts confirmation upon submission if option is enabled on form.
        if (this.options.confirmSubmission) {
            const submissionConfirmed = window.confirm(this.options.confirmationMessage);
            if (!submissionConfirmed) {
                e.preventDefault();
            }
        }
    }

    onChange(e) {
        if (this.options.isJsonOnly) {
            const isJsonFile = this.checkIfIsJson(e);
            if (!isJsonFile) {
                alert('Invalid file uploaded. Please upload a .json file.');
                return;
            }
        }

        if (this.options.submitOnChange) {
            this.$form.submit();
        }
    }

    addValidationPatterns() {
        this.$defaultValidation.attr('pattern', this.REGEX_PATTERNS.DEFAULT);
    }

    checkInputValidity() {
        let isValid = true;

        for (const inputToValidate of this.$inputs) {
            const inputValidity = inputToValidate.checkValidity();
            const isStrictStringValidation = $(inputToValidate).data('strictValidation');
            const inputValue = $(inputToValidate).val();

            if ($(inputToValidate).is(':visible')) {
                if (!inputValidity) {
                    this.setErrorState(inputToValidate);

                    isValid = false;
                }
            }
        }

        return isValid;
    }

    checkIfIsJson(e) {
        const file = e.target.files[0];
        const filename = file.name;
        const fileExtension = filename.split('.').pop();

        if (fileExtension === 'json') {
            return true;
        }
    }

    clearErrorStates() {
        this.$inputs.removeClass(this.STATE.ERROR);
        this.$labels.removeClass(this.STATE.VISUALLY_HIDDEN);
        this.$errorMessages.removeClass(this.STATE.ACTIVE);
    }

    setErrorState(input) {
        const errorInput = $(input);
        const errorToDisplay = errorInput.data('validationInput');
        const errorMessageToDisplay = $('[data-validation-error="' + `${errorToDisplay}` + '"]');
        const labelToHide = $('[data-validation-label="' + `${errorToDisplay}` + '"]');

        labelToHide.addClass(this.STATE.VISUALLY_HIDDEN);
        errorInput.addClass(this.STATE.ERROR);
        errorMessageToDisplay.addClass(this.STATE.ACTIVE);
    }
}
