import $ from 'jquery';
import EventEmitter from 'wolfy87-eventemitter';

/**
 * @class BaseComponent
 */
export default class BaseComponent {
    static defaults = {};

    /**
     * An empty jQuery object. Useful for defining DOM
     * properties in the constructor for elements that won't exist until render() is called.
     *
     * @property BaseComponent.$empty
     * @static
     * @type {jQuery}
     */
    $empty = $();


    constructor($element, options) {
        this.eventEmitter = new EventEmitter();
        /**
         * A reference to the containing DOM element.
         *
         * @default null
         * @property $element
         * @type {jQuery}
         * @public
         */
        this.$element = $element;

        /**
         * Tracks whether component is enabled.
         *
         * @default false
         * @property isEnabled
         * @type {bool}
         * @public
         */
        this.isEnabled = false;

        /**
         * Tracks whether user interactivity is enabled
         *
         * @default false
         * @property isInteractive
         * @type {bool}
         * @public
         */
        this.isInteractive = false;

        this.options = $.extend(true, {}, this.constructor.defaults, options);
    }

    /**
     * Initializes the UI Component View.
     *
     * Runs setupHandlers, createChildren and layout.
     * Exits early if it is already initialized.
     *
     * @method init
     * @returns {DemoView}
     * @private
     */
    init() {
        this.setupHandlers()
            .createChildren()
            .layout()
            .enable();

        return this;
    }

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {BaseComponent}
     * @private
     */
    setupHandlers() {
        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {BaseComponent}
     * @private
     */
    createChildren() {
        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     * This is called when destroying a view.
     *
     * @method removeChildren
     * @returns {BaseComponent}
     * @public
     */
    removeChildren() {
        return this;
    }

    /**
     * Performs measurements and applys any positioning style logic.
     * Should be run anytime the parent layout changes.
     *
     * @method layout
     * @returns {BaseComponent}
     * @public
     */
    layout() {
        return this;
    }


    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {BaseComponent}
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {BaseComponent}
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        return this;
    }

    /**
     * Destroys the component.
     * Tears down any events, handlers, elements.
     * Should be called when the object should be left unused.
     *
     * @method destroy
     * @returns {BaseComponent}
     * @public
     */
    destroy() {
        this.disable()
            .removeChildren();

        return this;
    }
}
