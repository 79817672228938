import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';
import FormValidationComponent from 'components/FormValidationComponent';

// Library Imports
import 'blueimp-file-upload/js/jquery.fileupload-image';

export default class FileUploadComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.formValidator = new FormValidationComponent(
            this.$element, { handleSubmission: false }
        );

        this.uploaders = [];

        this.init();
    }

    static defaults = {
        tableLayout: true,
        maxImageDimension: '600',
        maxImageSize: '2000000', // in bytes. 2mb
        maxFileSize: '5000000', // in bytes. 5mb
        isImage: false,
    }

    SELECTOR = {
        UPLOADER: '[data-fileupload-uploader]',
        INPUT: '[data-fileupload-input]',
        HEADINGS: '[data-fileupload-headings]',
        LIST: '[data-fileupload-list]',
        LIST_ITEM: '[data-fileupload-list-item]',
        INPUT_STRING: '[data-fileupload-input-string]',
        FILENAME: '[data-fileupload-filename]',
        IMAGE: '[data-fileupload-image]',
        RADIO: '[data-fileupload-radio]',
        RADIO_LABEL: '[data-fileupload-radio-label]',
        REMOVE: '[data-fileupload-remove]',
        WARNING: '[data-fileupload-warning]',
        PLACEHOLD: '[data-fileupload-placehold]',
        LOGO: '[data-fileupload-logo]',
        TYPE: '[data-fileupload-type]',
    }

    ATTRIBUTE = {
        UPLOADER: 'data-fileupload-uploader',
        RADIO: 'data-fileupload-has-radio',
        THUMBNAIL: 'data-fileupload-has-thumbnail',
        LIMIT: 'data-fileupload-limit',
        LIST_ITEM: 'data-fileupload-list-item',
    }

    STATE = {
        HIDDEN: 'u-isVisuallyHidden',
        LOADING: 's-isLoading',
    }

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {Object} FileUploadComponent
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onChangeHandler = this.onChange.bind(this);
        this.onFileUploadDoneHandler = this.onFileUploadDone.bind(this);
        this.onSubmitHandler = this.onSubmit.bind(this);
        this.onListClickHandler = this.onListClick.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {Object} FileUploadComponent
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$uploader = this.$element.find(this.SELECTOR.UPLOADER);
        this.$input = this.$uploader.find(this.SELECTOR.INPUT);
        this.$list = this.$uploader.find(this.SELECTOR.LIST);
        this.$logo = this.$element.find(this.SELECTOR.LOGO);
        this.$placehold = this.$element.find(this.SELECTOR.PLACEHOLD);

        this.$firstListItem = this.$element.find(this.SELECTOR.LIST_ITEM);

        return this;
    }

    /**
     * Performs measurements and applys any positioning style logic.
     * Should be run anytime the parent layout changes.
     *
     * @method layout
     * @returns {Object} FileUploadComponent
     * @public
     */
    layout() {
        super.layout();

        this.$input.each((index, element) => {
            this.uploaders.push(this.initFileUpload(element));
        });

        if (this.options.tableLayout === 'false') {
            this.$logo.hide();

            return this;
        }

        this.$uploader.each((index, element) => {
            const uploaderId = element.getAttribute(this.ATTRIBUTE.UPLOADER);

            this.addHeadingsById(uploaderId);
            this.updateUploaderLayoutById(uploaderId);
        });

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {Object} FileUploadComponent
     * @public
     */
    removeChildren() {
        super.removeChildren();

        this.$uploader = null;
        this.$input = null;
        this.$list = null;
        this.$logo = null;
        this.$placehold = null;

        return this;
    }


    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {Object} FileUploadComponent
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }

        this.isEnabled = true;

        this.$input.on('change', this.onChangeHandler);
        this.$input.on('fileuploaddone', this.onFileUploadDoneHandler);
        this.$element.on('submit', this.onSubmitHandler);
        this.$list.on('click', this.SELECTOR.REMOVE, this.onListClickHandler);

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {Object} FileUploadComponent
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        this.$input.off('change', this.onChangeHandler);
        this.$input.off('fileuploaddone', this.onFileUploadDoneHandler);
        this.$element.off('submit', this.onSubmitHandler);
        this.$list.off('click', this.SELECTOR.REMOVE, this.onListClickHandler);

        return this;
    }

    /**
     * Initializes the file upload plugin
     *
     * @method initFileUpload
     * @param {object} element
     * @returns {Object} FileUploadComponent
     * @public
     */
    initFileUpload(element) {
        return $(element).fileupload();
    }

    /**
     * Checks to see if files will include radio buttons
     *
     * @method _hasFileRadios
     * @param {object} element
     * @returns {Boolean}
     * @private
     */
    _hasFileRadios(element) {
        return $(element).attr(this.ATTRIBUTE.RADIO);
    }

    /**
     * Checks to see if files will include thumbnails
     *
     * @method _hasFileThumbnails
     * @param {object} element
     * @returns {Boolean}
     * @private
     */
    _hasFileThumbnails(element) {
        return $(element).attr(this.ATTRIBUTE.THUMBNAIL);
    }

    /**
     * Adds file uploader headings
     *
     * @method addHeadingsById
     * @param {String} uploaderId
     * @returns {Object} FileUploadComponent
     * @public
     */
    addHeadingsById(uploaderId) {
        const currentUploader = this.getUploaderById(uploaderId);
        const options = {
            hasRadio: this._hasFileRadios(currentUploader),
            hasThumbnail: this._hasFileThumbnails(currentUploader),
        };
        const $headings = this.createHeadingsMarkup(options);
        const $headingsContainer = $(currentUploader).find(this.SELECTOR.HEADINGS);

        $headingsContainer.append($headings);

        return this;
    }

    /**
     * Finds an uploader element based on file input id value
     *
     * @method getUploaderById
     * @param {String} uploaderId
     * @returns {Object} currentUploader DOM node
     * @public
     */
    getUploaderById(uploaderId) {
        const comparison = (i, element) => $(element).attr(this.ATTRIBUTE.UPLOADER) === uploaderId;
        const currentUploader = this.$uploader.filter(comparison).get(0);

        return currentUploader;
    }

    /**
     * Determines which uploader to add a file row to
     * Adds files to appropriate file arrays
     *
     * @method addRow
     * @param {String} uploaderId
     * @param {Object} file File
     * @returns {Object} FileUploadComponent
     * @public
     */
    addRow(uploaderId, file) {
        const currentUploader = this.getUploaderById(uploaderId);
        const options = {
            hasRadio: this._hasFileRadios(currentUploader),
            hasThumbnail: this._hasFileThumbnails(currentUploader),
        };
        const $newRow = this.createFileMarkup(file[0], options);
        const $currentList = $(currentUploader).find(this.SELECTOR.LIST);

        $newRow.addClass(this.STATE.LOADING);
        $currentList.append($newRow);

        if ($currentList.children().length === 1) {
            this.selectFirstImageAsDefault();
        }

        return this;
    }

    /**
     * Creates headings options
     *
     * @method createHeadingsMarkup
     * @param {Object} options { hasRadio, hasThumbnail }
     * @returns {Object} $markup jQuery object
     * @public
     */
    createHeadingsMarkup(options) {
        const $headingsMarkup = $(
            `<tr class="o-table-row">
                <th class="o-table-row-col o-table-row-col_10of12">
                    <span class="c-label c-label_sm c-label_leftAlign">File</span>
                </th>
                <th class="o-table-row-col o-table-row-col_2of12 o-table-cell_centerAlign">
                    <span class="c-label c-label_sm">Remove</span>
                </th>
            </tr>`
        );

        const $radioMarkup = $(
            `<th class="o-table-row-col o-table-row-col_2of12 o-table-cell_centerAlign">
                <span class="c-label c-label_sm">Default</span>
            </th>`
        );

        const $thumbnailMarkup = $(
            `<th class="o-table-row-col o-table-row-col_3of12">
                <span class="c-label c-label_sm c-label_leftAlign">Image</span>
            </th>`
        );

        if (options.hasThumbnail) {
            $headingsMarkup.prepend($thumbnailMarkup);
        }

        if (options.hasRadio) {
            $headingsMarkup.prepend($radioMarkup);
        }

        return $headingsMarkup;
    }

    /**
     * Creates file markup
     *
     * @method createFileMarkup
     * @param {Object} currentFile File
     * @param {Object} options { hasRadio, hasThumbnail }
     * @returns {Object} $markup jQuery object
     * @public
     */
    createFileMarkup(currentFile, options) {
        const reader = new FileReader();

        const $fileMarkup = $(
            `<tr class="o-table-row" data-fileupload-list-item>
                <td>
                    <span class="c-copy c-copy_white" data-fileupload-filename></span>
                </td>
                <td class="o-table-cell_centerAlign">
                    <button class="c-btn c-btn_unstyled" type="button" data-fileupload-remove>
                        <svg class="c-icon c-icon_inverse" role="img"
                            viewBox="0 0 23 24" width="23" height="24">
                            <use xlink:href="#icon_Close"></use>
                        </svg>
                    </button>
                </td>
            </tr>`
        );

        const $radioMarkup = $(
            `<td class="o-table-cell_centerAlign">
                <div class="c-checkbox c-checkbox_inverse c-checkbox_center">
                    <input
                        type="radio"
                        id=""
                        name="primaryImage"
                        class="c-checkbox-field"
                        data-fileupload-radio>
                    <label
                        for=""
                        class="c-checkbox-label c-checkbox-label_dim c-checkbox-label_isHidden"
                        data-fileupload-radio-label>
                        <span class="u-isVisuallyHidden"></span>
                    </label>
                </div>
            </td>`
        );

        const $thumbnailMarkup = $(
            `<td>
                <img src="" data-fileupload-image width="60">
            </td>`
        );

        $fileMarkup.find(this.SELECTOR.FILENAME).text(currentFile.name);

        reader.onload = e => {
            if (options.hasThumbnail) {
                $thumbnailMarkup.find(this.SELECTOR.IMAGE).attr('src', e.target.result);
                $thumbnailMarkup.find(this.SELECTOR.PLACEHOLD).hide();
            }

            if (options.hasRadio) {
                const index = ($fileMarkup.index()) + 1;
                const radioId = `primaryImage_${index}`;

                $radioMarkup.find(this.SELECTOR.RADIO).val(index);
                $radioMarkup.find(this.SELECTOR.RADIO).attr('id', radioId);
                $radioMarkup.find(this.SELECTOR.RADIO_LABEL).attr('for', radioId);
            }
        };

        if (options.hasThumbnail) {
            reader.readAsDataURL(currentFile);
            $fileMarkup.prepend($thumbnailMarkup);
        }

        if (options.hasRadio) {
            $fileMarkup.prepend($radioMarkup);
        }

        $fileMarkup.data('file', currentFile);

        return $fileMarkup;
    }

    /**
     * Checks to see if the number of files exceeds the max for that file input
     * Removes the oldest (first) uploaded row if necessary
     *
     * @method checkfileLimitById
     * @param {String} uploaderId data-fileupload-uploader value for a specific file input
     * @param {Integer} limit max number of file uploads allowed
     * @returns {Object} FileUploadComponent
     * @public
     */
    checkfileLimitById(uploaderId, limit) {
        const currentUploader = this.getUploaderById(uploaderId);
        const $currentRows = $(currentUploader).find(this.SELECTOR.LIST_ITEM);
        const $oldestRow = $currentRows.first();

        if ($currentRows.length >= limit) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Removes a specified row from the layout
     * Removes associated file from the file array
     *
     * @method removeRow
     * @param {String} uploaderId
     * @param {Object} $row jQuery object
     * @returns {Object} FileUploadComponent
     * @public
     */
    removeRow(uploaderId, $row) {
        $row.remove();
        this.updateUploaderLayoutById(uploaderId);

        return this;
    }

    /**
     * Updates the layout for a specific file uploader
     * Checks the number of rows and updates layout accordingly
     *
     * @method updateUploaderLayoutById
     * @param {String} uploaderId
     * @returns {Object} FileUploadComponent
     * @public
     */
    updateUploaderLayoutById(uploaderId) {
        const currentUploader = this.getUploaderById(uploaderId);
        const $currentRows = $(currentUploader).find(this.SELECTOR.LIST_ITEM);
        const $headings = $(currentUploader).find(this.SELECTOR.HEADINGS);
        const $warning = $(currentUploader).find(this.SELECTOR.WARNING);

        if ($currentRows.length > 0) {
            $headings.removeClass(this.STATE.HIDDEN);
            $warning.hide();
        } else {
            $headings.addClass(this.STATE.HIDDEN);
            $warning.show();
        }

        this.updateInputStringById(uploaderId);

        return this;
    }

    /**
     * Replaces the hidden input string with the most current file ids
     *
     * @method updateInputStringById
     * @param {String} uploaderId
     * @returns {Object} FileUploadComponent
     * @public
     */
    updateInputStringById(uploaderId) {
        const currentUploader = this.getUploaderById(uploaderId);
        const $rows = $(currentUploader).find(this.SELECTOR.LIST_ITEM);
        const $input = $(currentUploader).find(this.SELECTOR.INPUT_STRING);
        const fileIds = [];

        for (const row of $rows) {
            const fileId = $(row).attr(this.ATTRIBUTE.LIST_ITEM);
            fileIds.push(fileId);
        }

        $input.val(fileIds.toString());

        return this;
    }

    /**
     * Swaps out the placeholder image with logo image
     *
     * @method updateLogoUrl
     * @param {Object} file
     * @returns {Object} FileUploadComponent
     * @public
     */
    updateLogoUrl(file) {
        const logoReader = new FileReader();

        logoReader.onload = e => {
            this.$placehold.hide();
            this.$logo.show().attr('src', e.target.result);
        };
        logoReader.readAsDataURL(file[0]);

        return this;
    }

    /**
     * Uses FormValidationComponent to check input validity
     *
     * @method checkFormValidity
     * @returns {Boolean} isValid
     * @public
     */
    checkFormValidity() {
        this.formValidator.clearErrorStates();
        const isValid = this.formValidator.checkInputValidity();

        return isValid;
    }

    /*
        EVENT HANDLERS
    */

    /**
     * Handles data when plugin uploads file
     *
     * @method handleFileUploadDone
     * @param {Event} event
     * @param {Object} data
     * @public
     */
    onFileUploadDone(event, data) {
        if (this.options.tableLayout === 'false') {
            if (data.result[0].success !== true) {
                this.$logo.hide();
                this.$placehold.show();
                alert(data.result[0].message);

                return;
            }
        }

        const $target = $(event.target);
        const $currentUploader = $target.closest(this.SELECTOR.UPLOADER);
        const uploaderId = $currentUploader.attr(this.ATTRIBUTE.UPLOADER);
        const $rows = $currentUploader.find(this.SELECTOR.LIST_ITEM);
        const uploadedFile = data.files[0];

        const resultID = data.result[0].id;
        const radioId = `primaryImage_${resultID}`;

        for (const row of $rows) {
            const $rowMatch = $(row).filter(() => {
                return $(row, this).data('file') === uploadedFile;
            });

            if (data.result[0].success !== true) {
                this.removeRow(uploaderId, $rowMatch);
                alert(data.result[0].message);

                return;
            }

            $rowMatch.removeClass(this.STATE.LOADING);
            $rowMatch.attr(this.ATTRIBUTE.LIST_ITEM, data.result[0].id);

            $($rowMatch).find(this.SELECTOR.RADIO)
                        .val(resultID);

            $($rowMatch).find(this.SELECTOR.RADIO)
                        .attr('id', radioId);

            $($rowMatch).find(this.SELECTOR.RADIO_LABEL)
                        .attr('for', radioId);

            this.updateUploaderLayoutById(uploaderId);
        }
    }

    /**
     * Button click event for removing a file
     *
     * @method onListClick
     * @param {Event} event
     * @public
     */
    onListClick(event) {
        event.preventDefault();

        const $target = $(event.target);
        const $rowToRemove = $target.closest(this.SELECTOR.LIST_ITEM);
        const uploaderId = $target.closest(this.SELECTOR.UPLOADER).attr(this.ATTRIBUTE.UPLOADER);

        this.removeRow(uploaderId, $rowToRemove);
    }

    /**
     * onChange event for file input changes
     *
     * @method onChange
     * @param {Event} event
     * @public
     */
    onChange(event) {
        const file = event.target.files;
        const uploaderId = event.target.id;
        const limit = event.target.getAttribute(this.ATTRIBUTE.LIMIT);

        const uploadType = $(event.target).data('fileuploadType');

        const isValidFileSize = this.checkFileSize(file[0], uploadType);

        if (!isValidFileSize) {
            if (uploadType === 'image') {
                alert('Max file size is 2mb. Please upload a smaller size.');
            } else {
                alert('Max file size is 5mb. Please upload a smaller size.');
            }

            return;
        }

        // Validate Image if appropriate
        if (uploadType === 'image' || uploadType === 'logo') {
            const isImage = this.checkIfImage(file[0]);

            if (!isImage) {
                alert('Invalid file type. Please upload an image.');
                return;
            }

            this.getImageDimensions(file[0]);
        } else if (uploadType === 'limitedAttachment') {
            const isValidFile = this.validateLimitedFileType(file[0]);

            if (!isValidFile) {
                alert('Invalid file type. Please upload a pdf or jpg.');
                return;
            }
        } else {
            const isValidFile = this.validateFileType(file[0]);

            if (!isValidFile) {
                alert('Invalid file type. Please upload a pdf, doc, xls, txt, zip, or jpg.');
                return;
            }
        }

        const exceedsUploadLimit = this.checkfileLimitById(uploaderId, limit);

        if (limit && exceedsUploadLimit) {
            alert(`Maximum ${limit} files allowed.`);

            return;
        } else {
            if (this.options.tableLayout === 'false') {
                this.updateLogoUrl(file);
            } else {
                this.addRow(uploaderId, file);
            }
        }
    }

    checkFileSize(file, uploadType) {
        const fileSize = file.size;

        // check upload type
        if (uploadType === 'image') {
            // check file size
            if (fileSize >= this.options.maxImageSize) {
                return false;
            }
        } else {
            if (fileSize >= this.options.maxFileSize) {
                return false;
            }
        }

        return true;
    }


    checkIfImage(file) {
        const fileName = file.name;
        const validFileTypes = /(\.png|\.gif|\.jpg|\.jpeg)$/i;

        if (!validFileTypes.exec(fileName)) {
            return false;
        }

        return true;
    }

    validateFileType(file) {
        const fileName = file.name;
        const validFileTypes = /(\.pdf|\.doc|\.xls|\.txt|\.zip|\.jpg|\.jpeg)$/i;

        if (!validFileTypes.exec(fileName)) {
            return false;
        }

        return true;
    }

    validateLimitedFileType(file) {
        const fileName = file.name;
        const validFileTypes = /(\.pdf|\.jpg|\.jpeg)$/i;

        if (!validFileTypes.exec(fileName)) {
            return false;
        }

        return true;
    }

    getImageDimensions(file) {
        const imgReader = new FileReader();

        imgReader.onload = e => {
            const img = new Image();

            img.onload = e => {
                const imgWidth = img.width;
                const imgHeight = img.height;
                this.validateImageDimensions(imgWidth, imgHeight);
            };

            img.src = imgReader.result;
        };
        imgReader.readAsDataURL(file);
    }

    validateImageDimensions(imgWidth, imgHeight) {
        const imageWidth = imgWidth;
        const imageHeight = imgHeight;
        const rowToRemove = $('[data-fileupload-uploader="images"]')
                            .find($(this.SELECTOR.REMOVE).last());

        if (imageWidth > this.options.maxImageDimension ||
            imageHeight > this.options.maxImageDimension) {
            // TODO: This is quick, smelly fix to get through QA on time.
            // Should find better solution
            rowToRemove.trigger('click');
            this.$logo.hide();
            this.$placehold.show();

            setTimeout(() => {
                alert('Image is larger than required dimensions. Please upload a smaller image.');
            }, 100);
        }
    }

    /**
     * Select first image as default
     *
     * This method will select the first instance of an uploaded
     * image as the default via checkbox.
     *
     * @method selectFirstImageAsDefault
     * @public
     */
    selectFirstImageAsDefault() {
        const firstImageRow = this.$element.find(this.SELECTOR.LIST_ITEM).first();
        const defaultImage = $(firstImageRow).find(this.SELECTOR.RADIO)
                                             .prop('checked', true);
    }

    /**
     * onSubmit event for form
     *
     * @method onSubmit
     * @param {Event} event
     * @public
     */
    onSubmit(event) {
        const isValid = this.checkFormValidity();

        if (!isValid) {
            event.preventDefault();
        }
    }
}
