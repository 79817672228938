import $ from 'jquery';
window.$ = require('jquery');


// Manifests
import ComponentManifest from 'ComponentManifest';

/**
 * Application setup
 *
 * @class App
 */
export default class App {
    constructor() {
        this.init();
    }

    DATA_ATTRIBUTE = {
        INSTANCE: 'instance',
        COMPONENT: 'component',
        COMPONENT_OPTIONS: 'component-options',
    }

    init() {
        this.createComponents();
    }

    createComponents($scope) {
        /* eslint-disable */
        $scope = $scope instanceof $ ? $scope : $(document.body);
        /* eslint-enable */
        const $components = $scope
            .find(`[data-${this.DATA_ATTRIBUTE.COMPONENT}]`)
            .add($scope.filter(`[data-${this.DATA_ATTRIBUTE.COMPONENT}]`)
        );
        const l = $components.length;
        let i = 0;
        for (i = 0; i !== l; i++) {
            this.registerComponent($components.eq(i));
        }
    }

    registerComponent($component) {
        const name = $component.data(this.DATA_ATTRIBUTE.COMPONENT);
        const Constructor = ComponentManifest[name];
        const instance = new Constructor(
            $component, $component.data(this.DATA_ATTRIBUTE.COMPONENT_OPTIONS)
        );
        $component.data(this.DATA_ATTRIBUTE.INSTANCE, instance);

        return instance;
    }
}
