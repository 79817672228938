import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class ConditionalContentComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.init();
    }

    static defaults = {
        /**
         * A selector that will be used to attach the click event needed to open the modal.
         *
         * @property ConditionalContentComponent.options.triggerSelector
         * @type {Object}
         * @default null
         */
        triggerSelector: null,
    }


    SELECTOR = {
        CONTROLLER: '[data-conditional-control]',
        CONTENT: '[data-conditional-content]',
    }

    STATE = {
        HIDDEN: 'u-isHidden',
    }


    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {ConditionalContentComponent}
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onChangeHandler = this.onChange.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {ConditionalContentComponent}
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$contentController = this.$element.find(this.SELECTOR.CONTROLLER);
        this.$conditionalContent = this.$element.find(this.SELECTOR.CONTENT);

        return this;
    }

    /**
     * Performs measurements and applys any positioning style logic.
     * Should be run anytime the parent layout changes.
     *
     * @method layout
     * @returns {ConditionalContentComponent}
     * @public
     */
    layout() {
        super.layout();

        this.checkSelectedConditions();

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {ConditionalContentComponent}
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this.$element.on('change', this.onChangeHandler);


        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {ConditionalContentComponent}
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        return this;
    }

    /**
     * Loops through content selectors to get selected value
     * of content controller.
     *
     * @method checkSelectedConditions
     * @public
     */
    checkSelectedConditions() {
        const controls = this.$contentController;

        for (const control of controls) {
            if ($(control).is(':selected')) {
                const selectedContent = $(control).data('conditional-control');
                this.displayConditionalContent(selectedContent);
            }
        }
    }

    /**
     * Loops through conditional content and displays selected content.
     *
     * @method displayConditionalContent
     * @param {string} selectedContent
     * @public
     */
    displayConditionalContent(selectedContent) {
        const contentToShow = selectedContent;
        const conditionalContent = this.$conditionalContent;

        for (const content of conditionalContent) {
            if ($(content).data('conditional-content') === contentToShow) {
                $(content).removeClass(this.STATE.HIDDEN);
            }
        }
    }

    /**
     * Hides all conditional content.
     *
     * @method hideConditionalContent
     * @public
     */
    hideConditionalContent() {
        this.$conditionalContent.addClass(this.STATE.HIDDEN);
    }

    /*
        EVENT HANDLERS
    */

    /**
     * Element change event
     *
     * @method onChange
     * @param {event} e
     * @public
     */
    onChange(e) {
        e.preventDefault();
        this.hideConditionalContent();
        this.checkSelectedConditions();
    }
}
