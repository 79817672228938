import CSVImportComponent from 'components/CSVImportComponent';
import ConditionalContentComponent from 'components/ConditionalContentComponent';
import DrawerComponent from 'components/DrawerComponent';
import DropdownComponent from 'components/DropdownComponent';
import FileUploadComponent from 'components/FileUploadComponent';
import FormValidationComponent from 'components/FormValidationComponent';
import ModalComponent from 'components/ModalComponent';
import SelectUpdateComponent from 'components/SelectUpdateComponent';
import TabbedPanelsComponent from 'components/TabbedPanelsComponent';
import TypeaheadComponent from 'components/TypeaheadComponent';
import ToggleActionsController from 'components/ToggleActionsController';

const ComponentManifest = {
    CSVImportComponent,
    ConditionalContentComponent,
    DrawerComponent,
    DropdownComponent,
    FileUploadComponent,
    FormValidationComponent,
    ModalComponent,
    SelectUpdateComponent,
    TabbedPanelsComponent,
    ToggleActionsController,
    TypeaheadComponent,
};

export default ComponentManifest;
