import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';
import Awesomplete from 'awesomplete/awesomplete';

export default class TypeaheadComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.init();
        this.initAwesomplete();
    }

    static defaults = {
        requiresSelection: false,
    }

    SELECTOR = {
        INPUT: '[data-typeahead-input]',
        SUBMIT: '[data-typeahead-submit]',
        RESET: '[data-typeahead-reset]',
        LIST: '.awesomplete > ul',
        DATA_OPTION: '[data-typeahead-option]',
        ERROR: '[data-typeahead-error]',
        SELECTION_ERROR: '[data-typeahead-selectionError]',
    }

    STATE = {
        EXPANDED: 's-isExpanded',
        ACTIVE: 's-isActive',
    }

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {TypeaheadComponent}
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onSubmitHandler = this.onSubmit.bind(this);
        this.onTypeaheadOpenHandler = this.onTypeaheadOpen.bind(this);
        this.onTypeaheadCloseHandler = this.onTypeaheadClose.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {TypeaheadComponent}
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$typeaheadForm = this.$element;
        this.$typeaheadInput = this.$element.find(this.SELECTOR.INPUT);
        this.$typeaheadList = this.$element.find(this.SELECTOR.LIST);
        this.$dataOption = this.$element.find(this.SELECTOR.DATA_OPTION);
        this.$submit = this.$element.find(this.SELECTOR.SUBMIT);
        this.$reset = this.$element.find(this.SELECTOR.RESET);
        this.$error = this.$element.find(this.SELECTOR.ERROR);
        this.$selectionError = this.$element.find(this.SELECTOR.SELECTION_ERROR);

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {TypeaheadComponent}
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this.$typeaheadForm.on('submit', this.onSubmitHandler);

        // Custom event handler provided by plugin
        window.addEventListener('awesomplete-open', this.onTypeaheadOpenHandler);
        window.addEventListener('awesomplete-close', this.onTypeaheadCloseHandler);

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {TypeaheadComponent}
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }


    initAwesomplete() {
        for (const input of this.$typeaheadInput) {
            new Awesomplete(input, {
                list: '#typeaheadList',
                maxItems: '500',
            });
            $(this.SELECTOR.LIST).wrap('<div class="awesomplete-inner"></div>');
        }
    }

    onTypeaheadOpen() {
        this.$element.addClass(this.STATE.EXPANDED);
        this.$submit.hide();
        this.$reset.show();
    }

    onTypeaheadClose() {
        // Wrapped in timeout to get around plugin reset button issue.
        setTimeout(() => {
            this.$typeaheadForm.submit();
            this.$element.removeClass(this.STATE.EXPANDED);
            this.$submit.show();
            this.$reset.hide();
        }, 100);
    }

    onSubmit(event) {
        const typeaheadValue = this.$typeaheadInput.val();
        const typeaheadOptions = [];

        this.$dataOption.each(function() {
            const dataOption = $(this).text();
            typeaheadOptions.push(dataOption);
        });

        if (this.options.requiresSelection) {
            if (typeaheadOptions.indexOf(typeaheadValue) === -1) {
                event.preventDefault();
                this.$error.removeClass(this.STATE.ACTIVE);
                this.$selectionError.addClass(this.STATE.ACTIVE);
            }
        }
    }

    setTypeaheadErrorState() {
        this.$error.addClass(this.STATE.ACTIVE);
    }
}
