import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class DropdownComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.isActive = false;

        this.init();
    }

    SELECTOR = {
        LIST: '[data-dropdown-list]',
    };

    STATE = {
        ACTIVE: 's-isActive',
    };

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {object} DropdownComponent
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onClickHandler = this.onClick.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {object} DropdownComponent
     * @private
     */
    createChildren() {
        super.createChildren();

        this._$body = $(document.body);
        this.$list = this.$element.find(this.SELECTOR.LIST);

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {object} DropdownComponent
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {object} DropdownComponent
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this._$body.on('click', this.onClickHandler);

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {object} DropdownComponent
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        this._$body.off('click', this.onClickHandler);

        return this;
    }

    /**
     *  Sets initial layout
     *
     * @method layout
     * @returns {object} DropdownComponent
     * @public
     */
    layout() {
        const activeItem = this.$list.find(`.${this.STATE.ACTIVE}`);

        this.moveToTop(activeItem);

        return this;
    }

    /**
     * Toggles the component.
     *
     * @method toggleDropdown
     * @returns {object} DropdownComponent
     * @public
     */
    toggleDropdown() {
        this.isActive ? this.hideDropdown() : this.showDropdown();

        return this;
    }

    /**
     * Shows the component.
     *
     * @method showDropdown
     * @returns {object} DropdownComponent
     * @public
     */
    showDropdown() {
        this.isActive = true;

        this.$element.addClass(this.STATE.ACTIVE);

        return this;
    }

    /**
     * Hides the component.
     *
     * @method hideDropdown
     * @returns {object} DropdownComponent
     * @public
     */
    hideDropdown() {
        this.isActive = false;

        this.$element.removeClass(this.STATE.ACTIVE);

        return this;
    }

    /**
     * Removes the active properties from all items
     *
     * @method deactivateItem
     * @returns {object} DropdownComponent
     * @public
     */
    deactivateItems() {
        const listItems = this.$list.children();

        for (const item of listItems) {
            $(item).removeClass(this.STATE.ACTIVE);
        }

        return this;
    }

    /**
     * Makes the selected item active
     *
     * @method activateItem
     * @param {object} selectedItem
     * @returns {object} DropdownComponent
     * @public
     */
    activateItem(selectedItem) {
        // eslint-disable-next-line newline-per-chained-call
        const selectedListItem = $(selectedItem).parent().get(0);

        $(selectedListItem).addClass(this.STATE.ACTIVE);
        this.moveToTop(selectedListItem);

        return this;
    }

    /**
     * Moves the active list item to the top of the list
     *
     * @method moveToTop
     * @param {object} item
     * @returns {object} DropdownComponent
     * @public
     */
    moveToTop(item) {
        this.$list.prepend(item);

        return this;
    }

    /*
        EVENT HANDLERS
    */

    /**
     * Click event
     * Closes dropdown if click event is outside of it
     *
     * @method onClick
     * @param {event} e
     * @public
     */
    onClick(e) {
        const selectedItem = e.target;
        const targetIsInsideDropdown = $.contains(this.$element.get(0), e.target);

        if (!this.isActive && targetIsInsideDropdown) {
            e.preventDefault();
        }

        if (!targetIsInsideDropdown) {
            this.hideDropdown();
        } else {
            this.deactivateItems();
            this.activateItem(selectedItem);
            this.toggleDropdown();
        }
    }
}
