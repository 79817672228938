import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class DrawerComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.isActive = false;
        this.isTransitioning = false;
        this.transitionDuration = '';

        this.init();
    }

    SELECTOR = {
        TRIGGER(id) { return `[data-drawer-trigger="${id}"]`; },
        BD: '[data-drawer-bd]',
        BD_INNER: '[data-drawer-bd-inner]',
    };

    STATE = {
        ACTIVE: 's-isActive',
    };

    LISTENER = {
        TRANSITIONEND: [
            'webkitTransitionEnd',
            'msTransitionEnd',
            'transitionend', // FF
        ],
    };

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {object} Drawer
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onButtonClickHandler = this.onButtonClick.bind(this);
        this.onTransitionEndHandler = this.onTransitionEnd.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {object} Drawer
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$bd = this.$element.find(this.SELECTOR.BD);
        this.id = this.$element.find(this.SELECTOR.BD).attr('id');
        this.$id = `#${this.id}`;
        this.$bdInner = this.$bd.find(this.SELECTOR.BD_INNER);
        // eslint-disable-next-line new-cap
        this.$trigger = this.$element.find(this.SELECTOR.TRIGGER(this.id));


        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {object} Drawer
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {object} Drawer
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this.$trigger.on('click', this.onButtonClickHandler);

        // Loop through brower prefixed event listeners
        for (const transition of this.LISTENER.TRANSITIONEND) {
            document.addEventListener(transition, this.onTransitionEndHandler);
        }

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {object} Drawer
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        return this;
    }

    /**
     * Toggles the component.
     *
     * @method toggle
     * @returns {object} Drawer
     * @public
     */
    toggle() {
        this.isTransitioning = true;

        this.isActive ? this.hide() : this.show();

        return this;
    }

    /**
     * Shows the component.
     * Sets isActive and max-height styles
     * Sets aria-expanded state for accessibility
     *
     * @method show
     * @returns {object} Drawer
     * @public
     */
    show() {
        this.isActive = true;

        this.$element.addClass(this.STATE.ACTIVE);
        $(this.$id).css('max-height', this.getMaxHeight());
        $(this.$id)[0].setAttribute('aria-hidden', 'false');
        this.$trigger[0].setAttribute('aria-expanded', 'true');

        return this;
    }

    /**
     * Hides the component.
     * Sets isActive and max-height styles
     * Sets aria-expanded state for accessibility
     *
     * @method hide
     * @returns {object} Drawer
     * @public
     */
    hide() {
        this.isActive = false;

        this.$element.removeClass(this.STATE.ACTIVE);
        $(this.$id).css('max-height', 0);
        $(this.$id)[0].setAttribute('aria-hidden', 'true');
        this.$trigger[0].setAttribute('aria-expanded', 'false');

        return this;
    }

    /**
     * Retrieves bdInner height value to set bd max-height
     * Gets sum of all nested bdInner heights
     *
     * @method getMaxHeight
     * @returns {object} Drawer
     * @public
     */
    getMaxHeight() {
        // Find all the bdInner heights
        const inners = $.makeArray(this.$bdInner);
        let sum = 0;

        for (const inner of inners) {
            const height = $(inner).outerHeight();

            sum = sum + Number(height);
        }

        return sum;
    }

    /*
        EVENT HANDLERS
    */

    /**
     * Button click event
     * Exits early if CSS transition is still in progress
     *
     * @method onButtonClick
     * @param {event} e
     * @public
     */
    onButtonClick(e) {
        if (this.isTransitioning) {
            return;
        }

        this.toggle();
    }

    /**
     * CSS transition end event
     *
     * @method onTransitionEnd
     * @param {event} e
     * @public
     */
    onTransitionEnd(e) {
        this.isTransitioning = false;
    }
}
