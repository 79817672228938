import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class ToggleActionsController extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.init();
    }

    SELECTOR = {
        CHECKBOX: '[data-toggle-actions-checkbox]',
        ACTION: '[data-toggle-actions-action]',
        STATUS: '[data-toggle-actions-status]',
    };

    ATTRIBUTE = {
        CHECKED: 'checked',
        DISABLED: 'disabled',
    };

    STRING = {
        ENABLED: 'Actions are now enabled.',
        DISABLED: 'Actions are now disabled. Please select one or more proposals in order to enable actions.',
    }

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {ToggleActionsController}
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onCheckboxClickHandler = this.onCheckboxClick.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {ToggleActionsController}
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$checkboxes = this.$element.find(this.SELECTOR.CHECKBOX);
        this.$actions = this.$element.find(this.SELECTOR.ACTION);
        this.$status = this.$element.find(this.SELECTOR.STATUS);

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {ToggleActionsController}
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }


    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {ToggleActionsController}
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this.$checkboxes.on('click', this.onCheckboxClickHandler);

        this.setActionsStatus();

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {ToggleActionsController}
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        this.$checkboxes.off('click');

        return this;
    }

    /**
     * Determines if actions should be enabled or disabled
     *
     * @method setActionsStatus
     * @returns {ToggleActionsController}
     * @public
     */
    setActionsStatus() {
        const checkboxes = $.makeArray(this.$checkboxes);
        const checked = this.countChecked(checkboxes);
        const actions = $.makeArray(this.$actions);

        if (checked > 0) {
            this.enableActions(actions);
            this.$status.text(this.STRING.ENABLED);
        } else {
            this.disableActions(actions);
            this.$status.text(this.STRING.DISABLED);
        }

        return this;
    }

    /**
     * Counts how many checkboxes are checked
     *
     * @method countChecked
     * @param {Array} checkboxes
     * @returns {ToggleActionsController}
     * @public
     */
    countChecked(checkboxes) {
        return checkboxes.filter(checkbox => checkbox.checked).length;
    }

    /**
     * Enables actions (removes disabled from actions)
     *
     * @method enableActions
     * @param {Array} actions
     * @returns {ToggleActionsController}
     * @public
     */
    enableActions(actions) {
        for (const action of actions) {
            action.disabled = false;
        }

        return this;
    }

    /**
     * Disables actions
     *
     * @method disableActions
     * @param {Array} actions
     * @returns {ToggleActionsController}
     * @public
     */
    disableActions(actions) {
        for (const action of actions) {
            action.disabled = true;
        }

        return this;
    }

    /*
        EVENT HANDLERS
    */

    /**
     * Checkbox click event
     *
     * @method onCheckboxClick
     * @param {event} e
     * @public
     */
    onCheckboxClick(e) {
        const target = e.target;

        this.setActionsStatus();
    }
}
