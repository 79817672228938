import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';
// TODO: Find way of making this work: import 'papaparse/papaparse';
// TODO: Window on load error method is real hacky. Should find cleaner solution time pending.

export default class CSVImportComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.init();
    }

    static defaults = {
    }

    SELECTOR = {
        UPLOADER: '[data-csv-uploader]',
        STAGE_STATE: '[data-csv-stagingstate]',
        UPLOAD_STATE: '[data-csv-uploadstate]',
        COUNT: '[data-csv-count]',
    }

    STATE = {
        ERROR: 's-hasError',
    }

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {CSVImportComponent}
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onFormChangeHandler = this.onFormChange.bind(this);
        this.onLoadHandler = this.onLoad.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {CSVImportComponent}
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$form = this.$element;
        this.$uploader = this.$element.find(this.SELECTOR.UPLOADER);
        this.$stageState = this.$element.find(this.SELECTOR.STAGE_STATE);
        this.$uploadState = this.$element.find(this.SELECTOR.UPLOAD_STATE);

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {CSVImportComponent}
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this.$form.on('change', this.onFormChangeHandler);
        $(window).on('load', this.onLoadHandler);
        this.$uploader.on('click', this.onUploaderClickHandler);


        return this;
    }

    layout() {
        super.layout();

        this.$uploadState.hide();

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {CSVImportComponent}
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }

    onFormChange(e) {
        const file = e.target.files[0];
        const filename = file.name;
        const fileExtension = filename.split('.').pop();

        if (!(fileExtension === 'csv')) {
            alert('Invalid file uploaded. Please upload a .csv file.');

            this.$uploader.val('');

            return;
        }

        if (this.$uploader.val()) {
            this.$uploadState.show();
            this.$stageState.hide();
        }

        // TODO: This needs to be cleaned up and get json count
        if (fileExtension === 'csv') {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                error(err, file, inputElem, reason) {
                    // executed if an error occurs while loading the file,
                    // or if before callback aborted for some reason
                    alert('This product import has failed, please check your internet connection and try again.');
                },
                complete(results, file) {
                    // executed after all files are complete
                    const initialResultsArray = results.data;
                    const resultsArray = [];

                    for (let i = 0; i < initialResultsArray.length; i++) {
                        if (initialResultsArray[i].Name !== '') {
                            resultsArray.push(initialResultsArray[i]);
                        }
                    }

                    const count = resultsArray.length;
                    const uploadData = JSON.stringify(resultsArray);

                    $('[data-csv-count]').text(count);
                    $('[data-csv-input]').val(uploadData);

                    setTimeout(() => {
                        $('form').submit();
                    }, 100);
                },
            });
        } else {
            alert('Your products have been successfully imported.');
            $('form').submit();
        }
    }

    onLoad(e) {
        if (!this.$form.hasClass(this.STATE.ERROR) && this.$form.is(':visible')) {
            this.displaySuccessStates();
        }
    }

    displaySuccessStates() {
        alert('Your products have been successfully imported.');
    }

}
