import $ from 'jquery';
import BaseComponent from 'components/BaseComponent';

export default class TabbedPanelsComponent extends BaseComponent {
    constructor($element, options) {
        super($element, options);

        this.isActive = false;
        this.isTransitioning = false;

        this.init();
    }

    SELECTOR = {
        CONTROLS: '[data-tabbed-panels-controls]',
        TRIGGERS: '[data-tabbed-panels-trigger]',
        PANELS: '[data-tabbed-panels-panel]',
    };

    STATE = {
        ACTIVE: 's-isActive',
    };

    LISTENER = {
        TRANSITIONEND: [
            'webkitTransitionEnd',
            'msTransitionEnd',
            'transitionend', // FF
        ],
    };

    /**
     * Binds the scope of any handler functions.
     * Should only be run on initialization of the view.
     *
     * @method setupHandlers
     * @returns {object} TabbedPanels
     * @private
     */
    setupHandlers() {
        // Bind event handlers scope here
        this.onButtonClickHandler = this.onButtonClick.bind(this);
        this.onTransitionEndHandler = this.onTransitionEnd.bind(this);

        return this;
    }

    /**
     * Create any child objects or references to DOM elements.
     * Should only be run on initialization of the view.
     *
     * @method createChildren
     * @returns {object} TabbedPanels
     * @private
     */
    createChildren() {
        super.createChildren();

        this.$controls = this.$element.find(this.SELECTOR.CONTROLS);
        this.$triggers = this.$element.find(this.SELECTOR.TRIGGERS);
        this.$panels = this.$element.find(this.SELECTOR.PANELS);

        return this;
    }

    /**
     * Remove any child objects or references to DOM elements.
     *
     * @method removeChildren
     * @returns {object} TabbedPanels
     * @public
     */
    removeChildren() {
        super.removeChildren();

        return this;
    }

    /**
     * Enables the component.
     * Performs any event binding to handlers.
     * Exits early if it is already enabled.
     *
     * @method enable
     * @returns {object} TabbedPanels
     * @public
     */
    enable() {
        if (this.isEnabled) {
            return this;
        }
        this.isEnabled = true;

        this.$triggers.on('click', this.onButtonClickHandler);

        // Loop through brower prefixed event listeners
        for (const transition of this.LISTENER.TRANSITIONEND) {
            document.addEventListener(transition, this.onTransitionEndHandler);
        }

        return this;
    }

    /**
     * Disables the component.
     * Tears down any event binding to handlers.
     * Exits early if it is already disabled.
     *
     * @method disable
     * @returns {object} TabbedPanels
     * @public
     */
    disable() {
        if (!this.isEnabled) {
            return this;
        }
        this.isEnabled = false;

        return this;
    }

    /**
     * Provides layout for component elements.
     * Add roles for accessibility.
     *
     * @TODO: The current implementation relies on each trigger/tab set
     * having a unique name that is manually added to several places:
     * id for button inside trigger, data-id and aria-labelledby values for panel.
     * This is to allow flexibility for the layout (tabs don't have to be in order, etc.),
     * however, we may want to reconsider this as it is hard to rely on manual implementation.d
     *
     * @TODO: The current implementation does not reset the tabbed panel states on enable.
     * This is to allow flexibility in the layout so that any tabbed panel (and trigger)
     * can be set as the default panel displayed, but it has to be set manually.
     * OR should we just set the first tab as the default panel displayed?
     *
     * @method layout
     * @returns {object} TabbedPanels
     * @public
     */
    layout() {
        this.$controls.attr('role', 'tablist');
        this.$triggers.attr('role', 'presentation');
        this.$triggers.children().attr('role', 'tab');
        this.$triggers.children().attr('tab-index', '0');
        this.$panels.attr('role', 'tabpanel');

        return this;
    }

    /**
     * Updates status and layout for tabbed panels.
     * Exits early if current tab is already active.
     *
     * @method update
     * @param {event} target
     * @returns {object} TabbedPanels
     * @public
     */
    update(target) {
        this.isTransitioning = true;

        const $currentTrigger = $(target).parent();

        if ($currentTrigger.hasClass(this.STATE.ACTIVE)) {
            return;
        }

        this.resetTabbedPanelStates();
        this.setActiveTabbedPanelState(target);

        return this;
    }

    /**
     * Gets a tabbed panel by matching an id.
     *
     * @method getPanelById
     * @param {string} id
     * @returns {object} $panel
     * @public
     */
    getPanelById(id) {
        let $panel = '';

        $.each(this.$panels, function() {
            if ($(this).data('id') === id) {
                $panel = $(this);
            }
        });

        return $panel;
    }

    /**
     * Removes active styles from all tabbed panels.
     * Modifies aria values for accessibility.
     *
     * @method resetTabbedPanelStates
     * @returns {object} TabbedPanels
     * @public
     */
    resetTabbedPanelStates() {
        this.isActive = false;

        this.$triggers.removeClass(this.STATE.ACTIVE);
        this.$triggers.children().attr('aria-selected', 'false');

        this.$panels.removeClass(this.STATE.ACTIVE);
        this.$panels.attr('aria-hidden', 'true');

        return this;
    }

    /**
     * Sets active styles on current tabbed panel.
     * Modifies aria values for accessibility.
     *
     * @method setActiveTabbedPanelState
     * @param {event} target
     * @returns {object} TabbedPanels
     * @public
     */
    setActiveTabbedPanelState(target) {
        const $currentTarget = $(target);
        const $currentTrigger = $(target).parent();
        const $currentPanel = this.getPanelById($currentTarget.attr('id'));

        $currentTrigger.addClass(this.STATE.ACTIVE);
        $currentTarget.attr('aria-selected', 'true');

        $currentPanel.addClass(this.STATE.ACTIVE);
        $currentPanel.attr('aria-hidden', 'false');

        return this;
    }

    /*
        EVENT HANDLERS
    */

    /**
     * Button click event
     * Exits early if CSS transition is still in progress
     *
     * @method onButtonClick
     * @param {event} e
     * @public
     */
    onButtonClick(e) {
        e.preventDefault();

        const target = e.target;

        if (this.isTransitioning) {
            return;
        }

        this.update(target);
    }

    /**
     * CSS transition end event
     *
     * @method onTransitionEnd
     * @param {event} e
     * @public
     */
    onTransitionEnd(e) {
        this.isTransitioning = false;
    }
}
